<template>
  <div class="info-page">
    <p><b>Created</b> {{formatDate(item.created_at)}}</p>
    <p><b>Updated</b> {{formatDate(item.updated_at)}}</p>
    <p><b>Version</b> {{landing.version}}</p>
    <p><b>Tariff</b> {{tariff}}</p>
    <p><b>PWA</b> {{landing.advanced.global.pwa?'Enabled':'Disabled'}}</p>
    <p><b>Authorization</b> {{landing.advanced.global.auth?'Enabled':'Disabled'}}</p>
    <p><b>Weather widget</b> {{landing.advanced.global.show_weather?'Enabled':'Disabled'}}</p>
    <p><b>Project ID</b> {{$route.params.id}}</p>
    <p><b>Link to landing: </b> <a rel="noopener noreferrer" :href="`${VUE_APP_LINK}/m/${$route.params.id}`" target="_blank">{{VUE_APP_LINK}}/m/{{$route.params.id}}</a></p>
    <p><b>Selected languages: </b>{{langs}} </p>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "InfoPage",
  computed:{
    ...mapState(['landing']),
    ...mapState('objects',['item']),
    VUE_APP_LINK(){
      return process.env.VUE_APP_LINK
    },
    tariff(){
      if(this.landing.free){
        return 'FREE'
      }else if(this.landing.profi){
        return 'PROFI'
      }else{
        return "STANDART"
      }
    },
    langs(){
      let str = '';
      for(let i = 0;i<this.landing.selected_langs.length;i++){
        if(i === this.landing.selected_langs.length-1){
          str += this.landing.selected_langs[i]
        }else{
          str += this.landing.selected_langs[i]+', '
        }
      }
      return str
    }
  },
  methods: {
    formatDate(date){
      return this.$moment(date).format('DD.MM.YYYY HH:ss')
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

</style>